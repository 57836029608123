import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/services';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  isLoading = false;
  private subscription: Subscription;
  @Input() overlayColor = 'rgba(255, 255, 255, 0.8)';
  constructor(private loaderService: LoaderService) {
    this.subscription = this.loaderService.isLoading$.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
