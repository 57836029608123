import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard, authGuardForAuth } from './guards';
import {LandingPageModule} from './modules/landing-page/landing-page.module';
import { LoaderService } from './services';

const routes: Routes = [
  {
    path: 'home',
    canActivate: [authGuard],
    loadChildren: () =>
    import('./modules/home/home.module').then((m) => m.HomeModule)
  },
  {
    path: 'auth',
    canActivate: [authGuardForAuth],
    loadChildren: () =>
    import('./modules/authentication/authentication.module').then((m) => m.AuthenticationModule)
  },
  {
    path: '',
    // canActivate: [authGuardForAuth],
    loadChildren: () =>
    import('./modules/landing-page/landing-page.module').then((m) => m.LandingPageModule)
  },
  {
    path: '**',
    redirectTo: '/landing'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 

  constructor(private loaderService: LoaderService) {
    this.loaderService.showLoader();
    setTimeout(() => {
      this.loaderService.hideLoader();
    }, 1000); // Simulating a delay, remove this in your actual code
  }
}
