import { Component, OnDestroy, inject } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  // route: inject(ActivatedRoute)
  router = inject(Router);
  route = inject(ActivatedRoute);

  ngOnInit() {
    this.route.queryParams
      .subscribe((params:any) => {
        if(params.authExtensionKey){
          localStorage.setItem("from",params.authExtensionKey)
        }
        // this.setQueryParams(); 
      }
    );
  }

  setQueryParams(){
    const qParams: Params = {};
    this.router.navigate([], {
        relativeTo: this.route,
        queryParams: qParams,
        queryParamsHandling: '' 
    });
}
ngOnDestroy(): void {
  localStorage.removeItem("from")
  localStorage.removeItem("redirect")
}
}
