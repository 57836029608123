import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './backend.service';
import { BACKEND_URL_PATH } from '../utils/constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private apiService: ApiService) {}

  login(body: any) {
    return this.apiService.post(BACKEND_URL_PATH.login, body);
  }
  signup(body: any) {
    return this.apiService.post(BACKEND_URL_PATH.signup, body);
  }

  logout() {
    localStorage.clear();
  }
  getAuthStatus() {
    return localStorage.getItem('user') || false;
  }
}
