
import {
  HttpClient
} from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ApiService {
  private uri = "https://api.onexstudio.ai/api";
  // private uri = 'http://localhost:3000/api';
  constructor(private http: HttpClient) {}

  post(url: any, body?: any, opt = {}) {
    return this.http.post(this.uri + url, body, opt)
  }

  get(url: any, params = {}) {
    return this.http.get(this.uri + url, params)
  }

  patch(url: any, body: any,) {
    return this.http.patch(this.uri + url, body)
  }

  delete(url: any) {
    return this.http.request('delete', this.uri + url);
  }

  put(url: any, body: any, opt = {}) {
    return this.http.put(this.uri + url, body, opt)
  }


}
