import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  if (authService.getAuthStatus()) {
    return true;
  }
  router.navigate(['/auth/signin']);
  return false;
};
export const authGuardForAuth: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  if (!authService.getAuthStatus()) {
    return true;
  }
  router.navigate(['/home']);

  return false;
};
